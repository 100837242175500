



























import Vue from "vue";
import { ChangeLanguage } from "@/interfaces/shared/change_language";

export default Vue.extend({
  name: "ChangeLanguage",
  data(): ChangeLanguage {
    return {
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      language_options: [
        {
          key: "EN",
          value: "en"
        }
      ],
      current_language: localStorage.getItem("locale") || "en"
    };
  }
});
